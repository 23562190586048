<template>
	<div class="options">
		<div class="ysm">
			<img src="../assets/ysmimg/ysm.jpg" @click="goYsm" />
		</div>
	</div>
</template>

<script>
	
	export default{
		data:function(){
			return{
				
			}
		},
		methods:{
			goYsm(){
				this.$router.push("/ysmordertwo11");
			}
		},
		components:{
			
		}
	}
</script>

<style scoped="scoped">
	.options{
		width: 100%;
		height: 100%;
	}
	.ysm{
		width:100%;
		height: 40%;
	}
	.ysm img{
		width: 100%;
		height: 100%;
	}
</style>
